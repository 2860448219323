import React from 'react';
import { CommunityHeader, UsersTags, ShowMoreUsersModal } from '@tradetrax/web-common';
import { useAppContext } from 'app/AppContext';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CommunityDetailsHeader = ({ communityContext, linkToIntake }) => {
  const { appState, modal } = useAppContext();
  const { state, controller } = communityContext;
  const { community } = state.toObject();
  const users = appState.getIn(['account', 'users']);
  const schedulers = state
    .getIn(['users', 'subUsersScheduler'])
    ?.map(s => ({ name: s.get('name'), status: s.get('status'), initials: s.get('initials') }));
  const supers = state
    .getIn(['users', 'subUsersSuper'])
    ?.map(s => ({ name: s.get('name'), status: s.get('status'), initials: s.get('initials') }));

  const handleShowMoreUsers = () =>
    modal.open(ShowMoreUsersModal, { supers, schedulers, title: `Community: ${community.get('name')}` });

  return (
    <div className="d-flex">
      <CommunityHeader
        className="ml-4 px-1"
        community={community}
        controller={controller}
        domain="subs"
        linkToIntake={linkToIntake}
        loggedUserId={appState.getIn(['user', '_id'])}
        users={users}
      >
        <>
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Super Assigned"
            users={supers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Scheduler Assigned"
            users={schedulers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
        </>
      </CommunityHeader>
      <div className=" d-flex flex-column ml-auto mr-2">
        <ManageContainer className="mr-2 btn btn-link" onClick={linkToIntake}>
          <FontAwesomeIcon icon="list-check" className="mr-2" />
          {`Manage Intake`}
        </ManageContainer>
      </div>
    </div>
  );
};
const ManageContainer = styled.button`
  display: block;
  white-space: nowrap;
  padding-top: 0;
  margin-bottom: 15px;
`;
