import React from 'react';
import * as yup from 'yup';
import { fromJS } from 'immutable';
import { useAppContext } from 'app/AppContext';
import { useController } from '@tradetrax/web-common';
import * as actions from './CustomRoleDetailsActions';

export const NOTIFICATIONS = 'notifications';
export const FILTER_SETS = 'filter-sets';
export const PERMISSIONS = 'permisions';

export const MY_TASKS = 'my-tasks';
export const RELEVANT_TASKS = 'relevant-tasks';
export const LEAD_TIME = 'lead-time';

const emptyCustomRole = fromJS({
  name: '',
  active: false,
  settings: {
    notifications: {},
  },
});

const emptyState = fromJS({
  customRole: emptyCustomRole,
  tab: NOTIFICATIONS,
  selectedSection: MY_TASKS,
  filterSets: [],
});

export const nameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}~\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});

export const CustomRoleDetailsContext = (roleId, settingsContext) => {
  const appContext = useAppContext();
  const loggedUser = appContext.appState.get('user');
  const { customRoles } = settingsContext.state.toObject();
  const settingsController = settingsContext.controller;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    customRoles,
    settingsController,
  });

  React.useEffect(() => {
    controller.readCustomRole(roleId);
    controller.readFilterSets(roleId);
  }, [controller, roleId]);

  return { state, controller, loggedUser };
};

export function checkValidName(name, customRoles, setError) {
  const nameExisis = customRoles.filter(role => role.get('name').toLowerCase() === name.toLowerCase());
  if (nameExisis.size > 0 && setError) setError('name', { type: 'notMatch', message: 'Name already exists' });
  return !nameExisis.size;
}
