import React, { useEffect, useRef } from 'react';
import { fromJS } from 'immutable';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell, EmptyState } from '@tradetrax/web-common';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { useAppContext } from 'app/AppContext';
import { INSTALLER_TAB, MINIMUM_BATCH_SIZE, USER_TYPE } from './IntakeController';
import navigate from 'app/navigate';
import cn from 'classnames';
import { handleTableScrollStickyColumn2, hasPermissionForAssignedTask } from '@tradetrax/web-common/lib/utils';
import { SelectAllCheckbox } from '@tradetrax/web-common/lib/OuttakeIntake/SelectAllCheckbox';
import { MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { JobName } from '@tradetrax/web-common/lib/OuttakeIntake/JobName';

const COL_WIDTHS = {
  span: 32,
  jobName: 18 * 16,
  assignee: 16 * 16,
  taskName: 16 * 15,
};

export function IntakeTasks({ tab, communityId, state, controller, loaderRef, isFiltering }) {
  const appContext = useAppContext();
  const { account, appState, hasPermission } = appContext;
  const { tasks, totalCount, maxCount } = state.get('allTasks').toObject();
  const title = isFiltering ? 'No Matches Found' : 'No Tasks Added';
  const body = isFiltering
    ? "When there's a result that meets the filters, it will appear here."
    : 'When a Task of a Job from this Community is assigned to your account it will appear here.';
  const isEmpty = totalCount === 0;
  const loggedUserId = appState.getIn(['user', '_id']);
  const userType = USER_TYPE[tab];
  const isInstaller = userType.dataField === INSTALLER_TAB;
  const assigneeOptions = isInstaller ? 'assigneesActive' : 'regularUsersActive';
  const scrollDivRef = useRef(null);
  const selectedTasks = state.getIn(['selectedTasks', tab]);
  const canAssignUsers = isInstaller
    ? hasPermission('task_update_installer') || hasPermission('task_update_installer', 'assigned')
    : hasPermission('task_user_assignment');
  const canAssignInstaller = task =>
    hasPermissionForAssignedTask('task_update_installer', hasPermission, task, loggedUserId);
  const isThereAnyTaskWithoutPermission = isInstaller && !tasks.some(item => canAssignInstaller(item.get('task')));

  const rowClassName = index => {
    const item = tasks.get(index);
    const className = !item || item === EMPTY_ROW ? 'loading' : '';
    if (index < 0 || !item) return className;
    const isDisabled = isInstaller && !canAssignInstaller(item.get('task'));
    const isSelected =
      !isDisabled &&
      (selectedTasks.get('taskIds').indexOf(item.get('id')) >= 0 ||
        (selectedTasks.get('selectAll') && selectedTasks.get('notIncludeTaskIds').indexOf(item.get('id')) < 0));
    return `${className} ${isSelected ? 'selected' : ''}`;
  };

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={params => controller.readCommunityTasks({ communityId, tab, ...params })}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        ref={loaderRef}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div
            style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}
            className={cn({ 'd-none': isEmpty })}
            ref={scrollDivRef}
          >
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1100 ? width : 1100;
                return (
                  <Table
                    className="trx-table stickyTable stickyColumn2"
                    estimatedRowSize={80}
                    headerHeight={40}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    overscanRowCount={2}
                    ref={registerChild}
                    rowClassName={({ index }) => rowClassName(index)}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    rowHeight={80}
                    width={minWidth}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                    <Column
                      dataKey="task"
                      minWidth={350}
                      width={COL_WIDTHS.taskName}
                      headerClassName="d-flex align-items-center"
                      headerRenderer={() => (
                        <SelectAllCheckbox
                          selectedTasks={selectedTasks}
                          controller={controller}
                          show={canAssignUsers}
                          disabled={isThereAnyTaskWithoutPermission}
                        />
                      )}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const isChecked = selectedTasks.get('taskIds').indexOf(rowData.get('id')) >= 0;
                        const isDisabled = isInstaller && !canAssignInstaller(cellData);
                        const isExcluded =
                          isDisabled || selectedTasks.get('notIncludeTaskIds').indexOf(rowData.get('id')) >= 0;
                        return (
                          <div className="d-flex align-items-center">
                            <input
                              className={cn('mr-2', {
                                'd-none': !canAssignUsers,
                              })}
                              type="checkbox"
                              disabled={isDisabled}
                              checked={(selectedTasks.get('selectAll') || isChecked) && !isExcluded}
                              onChange={({ target }) =>
                                controller.onSelectCheckbox({
                                  checked: target.checked,
                                  task: rowData,
                                  tab,
                                })
                              }
                            />
                            <span className="text-truncate font-weight-bold">{cellData.get('name')}</span>
                            <MultiFamilyIcon task={cellData} isMultiFamily={!!cellData.get('multiFamily')} />
                          </div>
                        );
                      })}
                    />
                    <Column
                      label="Job"
                      dataKey="job"
                      width={COL_WIDTHS.jobName}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const linkToJob = navigate.to.JobDetails({ jobId: cellData.get('id') }, false);
                        return <JobName job={cellData} task={rowData.get('task')} linkToJob={linkToJob} />;
                      })}
                    />
                    <Column
                      label={`${isInstaller ? 'Installer Assignee' : userType.label}`}
                      dataKey="task"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const isDisabled = isInstaller && !canAssignInstaller(cellData);
                        const assigneeId = cellData.getIn([userType.dataField, '_id']);
                        const assignee = assigneeId
                          ? fromJS({
                              _id: assigneeId,
                              name: cellData.getIn([userType.dataField, 'name']),
                            })
                          : null;
                        return (
                          <AssigneeSelect
                            index={rowIndex}
                            users={account.get('assignees')}
                            options={account.get(assigneeOptions)}
                            currentUser={assignee}
                            hasPermission={canAssignUsers && !isDisabled}
                            loggedUserId={loggedUserId}
                            onChange={assignee =>
                              controller.assignUserToTask({ rowData, tab, assignee: fromJS(assignee) })
                            }
                          />
                        );
                      })}
                    />
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
      {isEmpty && <EmptyState icon="wrench" title={title} body={body} />}
    </>
  );
}
