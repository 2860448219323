import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@tradetrax/web-common';
import { trimSpaces, emptyStringToNull } from '@tradetrax/web-common/lib/utils';
import { PopoverSMS } from '@tradetrax/web-common/lib/Popover';
import { yupResolver } from '@hookform/resolvers';
import { NullMuted } from './MiniProfile';
import * as yup from 'yup';

const profileSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[^+@÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[^+@÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
});

export const MyProfileDetails = ({ user, controller, alert, logout }) => {
  const {
    register,
    errors,
    getValues,
    trigger,
    setError,
    reset,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      phone: user.get('phone') || '',
      role: user.get('role') || '',
    },
  });
  const handleError = err => {
    if (err.params) {
      err.params.forEach(error => setError(error.param, { type: 'manual', message: err.detail }));
    } else {
      const message = 'There was a problem updating this user. Please try again.';
      alert.error({ message: err.detail || message });
    }
  };
  const onBlur = async e => {
    const field = e.target.name;
    const isValid = await trigger(field);
    const value = getValues(field);
    if (isValid && (field === 'phone' || value)) {
      controller.updateUser(user, { [field]: value || null }).catch(handleError);
      reset({ field: value });
    }
  };

  const email = user.get('email');
  const hasPhone = !!getValues('phone');
  const disableSMS = !user.get('phone') || (dirtyFields.phone && errors.phone);
  const allowSMS = user.getIn(['settings', 'allowSMS']);

  return (
    <div className="d-flex flex-column ml-4 mt-4 pt-2" style={{ width: '440px' }}>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column w-50 pr-2">
          <Form.Group controlId="firstName" className="mb-4">
            <span className="font-size-14">First Name</span>
            <Form.Control
              type="text"
              size="lg"
              name="firstName"
              isInvalid={errors.firstName}
              isValid={!errors.firstName && dirtyFields.firstName}
              ref={register}
              onBlur={onBlur}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.firstName?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> First Name is valid
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-column w-50 pl-2">
          <Form.Group controlId="lastName" className="mb-4">
            <span className="font-size-14">Last Name</span>
            <Form.Control
              type="text"
              size="lg"
              name="lastName"
              isInvalid={errors.lastName}
              isValid={!errors.lastName && dirtyFields.lastName}
              ref={register}
              onBlur={onBlur}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.lastName?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Last Name is valid
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <input type="hidden" name="role" ref={register} />
      <span className={'font-size-14'}>Email</span>
      <NullMuted className="mb-3" value={email} />
      <Form.Group className="mb-2" controlId="phone">
        <span className="font-size-14">Phone</span>
        <Form.Control
          type="text"
          size="lg"
          name="phone"
          isInvalid={errors.phone}
          isValid={hasPhone && !errors.phone && dirtyFields.phone}
          ref={register}
          onBlur={onBlur}
          placeholder="Ex. 602 555 7890"
        />
        <span className="text-secondary font-size-14">*Optional</span>
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" /> {errors.phone?.message}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">
          <FontAwesomeIcon icon="circle-check" /> Phone number is valid
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex flex-row align-items-center">
        <Form.Check
          id="receive-sms"
          checked={allowSMS}
          disabled={disableSMS}
          type="switch"
          label="I want to receive text messages"
          onChange={() => controller.toggleSMSPermission()}
        />
        <PopoverSMS />
      </div>
      <div className="d-flex flex-row justify-content-start mt-4 pt-2 mb-1">
        <PrimaryButton onClick={() => controller.resetPassword(user, true)}>Reset Password</PrimaryButton>
      </div>
      <hr className="border-muted w-100" />
      <button className="my-1 btn btn-link mr-auto text-danger pl-0" onClick={() => logout()}>
        <FontAwesomeIcon icon="right-from-bracket" /> Log Out
      </button>
    </div>
  );
};
