import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

const DATE_FORMAT_ISO = 'YYYY-MM-DD';

export const PlanningWeeks = ({ context, visible, kind }) => {
  const { state } = context;
  const weeks = state.get(kind);

  if (!visible) return null;

  return (
    <>
      {weeks.map(week => (
        <PlanningWeek context={context} week={week} kind={kind} key={week.getIn(['interval', 'from'])} />
      ))}
    </>
  );
};

const PlanningWeek = ({ className, context, week, kind }) => {
  const from = moment(week.getIn(['interval', 'from']), DATE_FORMAT_ISO);
  const to = moment(week.getIn(['interval', 'to']), DATE_FORMAT_ISO);
  const isSameMonth = from.month() === to.month();

  return (
    <div className={className} data-testid="planning-week">
      <h4 className="font-weight-bold">
        Week: {from.format('MMMM[ ]D[ - ]')}
        {to.format(isSameMonth ? 'D' : 'MMMM D')}
      </h4>
      {week.get('commitments').map(task => (
        <PlanningTask week={week} task={task} context={context} kind={kind} key={task.get('_id')} />
      ))}
    </div>
  );
};

const canCommitOrDenyAll = communities => {
  const thereArePending = !!communities.find(c => c.get('status') === null);
  const canCommitAll = thereArePending && !communities.find(c => c.get('status') === 'denied');
  const canDenyAll = thereArePending && !communities.find(c => c.get('status') === 'committed');

  return { canDenyAll, canCommitAll };
};

const UnstyledPlanningTask = ({ className, week, task, context, kind }) => {
  const { controller, isReadOnly } = context;
  const communities = task.getIn(['buildersByTask', 0, 'communitiesByTask']);
  const { canDenyAll, canCommitAll } = canCommitOrDenyAll(communities);
  const onDeny = community => controller.denyCommunity(kind, week, task, community);
  const onCommit = community => controller.commitCommunity(kind, week, task, community);

  const ButtonsHeader = () => (
    <>
      <div className="button-wrapper">
        <button
          onClick={() => controller.denyTaskWeek(kind, week, task)}
          className="btn btn-link text-secondary font-size-14"
          disabled={!canDenyAll || isReadOnly}
        >
          Deny All
        </button>
      </div>
      <div className="button-wrapper">
        <button
          onClick={() => controller.commitTaskWeek(kind, week, task)}
          className="btn btn-link font-size-14"
          disabled={!canCommitAll || isReadOnly}
        >
          Commit All
        </button>
      </div>
    </>
  );

  return (
    <div className={className} data-testid="planning-task">
      <header className="d-flex flex-row">
        <label className="font-weight-bold">{task.get('name')}</label>
        {kind === 'upcoming' ? (
          <span className="font-weight-bold text-gray-200 font-size-14">Expected Requests</span>
        ) : (
          <ButtonsHeader />
        )}
      </header>
      <div>
        {communities.map(community => (
          <CommunityTask
            community={community}
            onDeny={onDeny}
            onCommit={onCommit}
            key={community.get('communityId')}
            isReadOnly={isReadOnly}
            kind={kind}
          />
        ))}
      </div>
    </div>
  );
};

const CommunityTask = ({ community, onCommit, onDeny, isReadOnly, kind }) => {
  const status = community.get('status');
  const Buttons = () => (
    <>
      <div className="button-wrapper">
        {status === null ? (
          <button
            onClick={() => onDeny(community)}
            disabled={isReadOnly}
            className="btn btn-link text-secondary deny-task font-size-14"
          >
            Deny
          </button>
        ) : (
          <StatusLabel kind="deny" status={status} />
        )}
      </div>
      <div className="button-wrapper">
        {status === null ? (
          <button onClick={() => onCommit(community)} disabled={isReadOnly} className="btn btn-link font-size-14">
            Commit
          </button>
        ) : (
          <StatusLabel kind="commit" status={status} />
        )}
      </div>
    </>
  );
  return (
    <div data-testid="planning-community-task" className="task-row d-flex flex-row my-2 align-items-center">
      <span className="task-count">{community.get('count')}</span>
      <CommunityName className="task-name mr-auto text-truncate">{community.get('name')}</CommunityName>
      {kind === 'upcoming' ? null : <Buttons />}
    </div>
  );
};

const UnstyledStatusLabel = ({ kind, status, className }) => {
  if (kind === 'deny' && status !== 'denied') return null;
  if (kind === 'commit' && status !== 'committed') return null;

  return <span className={className}>{status}</span>;
};

const StatusLabel = styled(UnstyledStatusLabel)`
  ${props => `
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  padding: 6px 12px;
  color: ${props.status === 'denied' ? colors.red_30 : colors.green_30};
`}
`;

export const PlanningTask = styled(UnstyledPlanningTask)`
  background: #f9f9fa;
  border-radius: 8px;
  padding: 22px 42px 16px 30px;
  margin-top: 12px;
  margin-bottom: 8px;

  header {
    border-bottom: solid 1px ${colors.gray500};
  }

  .button-wrapper {
    width: 120px;
    text-align: right;
    > .btn:not(.deny-task) {
      font-weight: bold;

      &:hover {
        color: ${colors.blue400};
      }
    }

    > .btn.text-secondary:hover {
      color: ${colors.gray800} !important;
    }
  }

  .task-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }
`;

const CommunityName = styled.span`
  width: 42%;
  @media (min-width: 992px) {
    width: 60%;
  }
`;
