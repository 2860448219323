import { fromJS } from 'immutable';
import { subsService } from 'services';
import { markAsSideEffect } from '@tradetrax/web-common';
import { MultipleAssigneeModal } from '@tradetrax/web-common/lib/Community/MultipleAssigneeModal';
import { plural, orderDesc } from '@tradetrax/web-common/lib/utils';

export function readCommunity(communityId) {
  return subsService
    .readCommunity({}, { params: { communityId } })
    .then(community => {
      community.areaManagers = orderDesc(community.areaManagers, 'name');
      return state => state.set('community', fromJS(community));
    })
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

export function loadMoreRows({ startIndex, stopIndex, communityId }) {
  return subsService
    .getCommunityJobs({}, { params: { communityId }, query: { start_index: startIndex, stop_index: stopIndex } })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

      return state =>
        state
          .set('totalCount', totalCount)
          .update('jobs', jobs => jobs.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
    });
}

export function setAreaManager(user, communityId) {
  const managerId = user ? user._id : null;
  const newAreaManager = fromJS({
    _id: managerId,
    name: managerId ? user.firstName + ' ' + user.lastName : '',
  });
  return subsService
    .updateCommunity({ managerId }, { params: { communityId } })
    .then(() => {
      const setRemoved = user ? 'set' : 'removed';
      this.alert.success({ message: `Area Manager successfully ${setRemoved} for this Community.` });
      return state => state.setIn(['community', 'areaManager'], newAreaManager);
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem setting Area Manager for this Community. Please try again.' });
      return state => state;
    });
}

export function readCommunityUsers(communityId) {
  return subsService
    .readCommunityUsers({}, { params: { communityId } })
    .then(users => state => state.set('users', fromJS(users)));
}

markAsSideEffect(updateCommunityHeader);
export function updateCommunityHeader({ action, community }) {
  if (action !== 'update') return;

  this.controller.dispatch([state => state.setIn(['community', 'areaManager'], fromJS(community.subAreaManagers))]);
}

markAsSideEffect(openMultipleAssigneeModal);
export async function openMultipleAssigneeModal(initView) {
  const community = this.state.get('community');
  const props = {
    initView,
    community,
  };
  const { isAccept, areaManagers } = await this.modal.open(MultipleAssigneeModal, props);
  if (!isAccept) return;

  const communityId = community.get('_id');
  const areaManagersIds = areaManagers.map(item => item.get('_id'));
  subsService
    .updateCommunity({ areaManagersIds }, { params: { communityId } })
    .then(() => {
      const orderedAreaManagers = orderDesc(areaManagers.toJS(), 'name');
      this.controller.dispatch([state => state.setIn(['community', 'areaManagers'], fromJS(orderedAreaManagers))]);
      this.alert.add({
        message: `Area ${plural(areaManagers.size, 'Manager')} successfully set for this Community.`,
        variant: 'success',
      });
    })
    .catch(error =>
      this.alert.add({
        message: 'There was a problem setting Area Manager for this Community. Please try again.',
        variant: 'danger',
      })
    );
}
